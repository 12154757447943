import React, { useContext, useEffect, useState } from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { PageContainer } from '../../components/Wrappers';
import {
    ColumnTitle,
    LinkToModal,
    TableBody,
    TableContainer,
    TableHeader,
    TableItem,
    TableRow,
} from '../../components/Table';
import IconManager, { IconType } from 'shared/components/IconManager';
import { Theming } from '../../theming';
import { Admin } from 'shared/types/User';
import { BaseButton, ButtonLink } from 'shared/components/Buttons';
import styled from 'styled-components';
import { CreateAdmin } from './CreateAdmin';
import { RemoveAdmin } from './RemoveAdmin';
import { useAsync } from 'shared/hooks/useAsync';
import { adminApiManager } from '../../network/apiManager';
import { ErrorMessage, InfoText } from 'shared/components/Text';
import LoadingSpinner from 'shared/components/Loader';
import { UserContext } from '../../context/UserContext';
import _ from 'lodash';
import { Modal } from 'shared/components/Modal';

export const AdminList: RFC = () => {
    const { currentLogin } = useContext(UserContext);

    const [adminList, setAdminList] = useState<Admin[] | null>(null);
    const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);

    //modals
    const [showCreateAdmin, setShowCreateAdmin] = useState<boolean>(false);
    const [showRemoveAdmin, setShowRemoveAdmin] = useState<boolean>(false);
    const [linkSentMessage, setLinkSentMessage] = useState<string>('')

    useEffect(() => {
        //fetch list on load
        getAdminList.execute();

        //clear errors on unmount
        return getAdminList.clearError();
    }, []);

    const getAdminListAsync = async () => {
        //clear errors
        getAdminList.clearError();
        try {
            const getAdminsResponse = await adminApiManager.Users.getAdmins({});
            setAdminList(getAdminsResponse);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const resendInviteAsync = async (username) => {
        try {
            await adminApiManager.Auth.resendInvitation({ username });
            setLinkSentMessage(`An invitation to create an admin account has been sent to ${ username }`);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const getAdminList = useAsync<void>(getAdminListAsync, "Error fetching data. Please check your connection and try again.");
    const resendInvite = useAsync<void>(async (username: string) => resendInviteAsync(username), "Error sending your request. Please check your connection and try again.")

    return (
        <>
            <AddButtonWrapper>
                <BaseButton
                    text={"Add New Admin"}
                    width={"auto"}
                    onClick={() => setShowCreateAdmin(true)}
                />
            </AddButtonWrapper>
            <PageContainer>
                {!!adminList ? (
                    <>
                        <TableContainer>
                            <TableHeader>
                                <TableRow>
                                    <ColumnTitle>Name</ColumnTitle>
                                    <ColumnTitle>Email</ColumnTitle>
                                    <ColumnTitle>Institution</ColumnTitle>
                                    <ColumnTitle>Manage Admins</ColumnTitle>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {adminList.map((admin, index) => {
                                    const isCurrentAdmin = _.isEqual(currentLogin, admin);

                                    return (
                                        <TableRow key={index} rowIndex={index} disabled={!admin.confirmed}>
                                            <TableItem>{`${admin.firstName} ${admin.lastName}`}</TableItem>
                                            <TableItem>{admin.username}</TableItem>
                                            <TableItem>{admin.institution}</TableItem>
                                            <TableItem>
                                                {admin.confirmed ?
                                                    <LinkToModal
                                                        disabled={isCurrentAdmin}
                                                        onClick={() => {
                                                            //prevent admins from deleting themselves
                                                            if (!isCurrentAdmin) {
                                                                setSelectedAdmin(admin);
                                                                setShowRemoveAdmin(true);
                                                            }
                                                        }}
                                                    >
                                                        <IconManager
                                                            type={IconType.MINUS_CIRCLE}
                                                            stroke={!isCurrentAdmin ? Theming.errorColor : Theming.iconColorDisabled}
                                                            size={20}
                                                        />
                                                    </LinkToModal>
                                                :
                                                    <ButtonLink
                                                        text={'Resend Invite'}
                                                        onClick={() => resendInvite.execute(admin.username)}
                                                    />
                                                }
                                            </TableItem>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </TableContainer>
                        <CreateAdmin
                            isOpen={showCreateAdmin}
                            hide={() => setShowCreateAdmin(false)}
                            onConfirm={() => {
                                //close modal and refresh admin list
                                setShowCreateAdmin(false);
                                getAdminList.execute();
                            }}
                        />
                        <RemoveAdmin
                            isOpen={showRemoveAdmin}
                            hide={() => {
                                setShowRemoveAdmin(false);
                                setSelectedAdmin(null);
                            }}
                            onConfirm={() => {
                                //close modal, clear selected admin, and refresh admin list
                                setShowRemoveAdmin(false);
                                setSelectedAdmin(null);
                                getAdminList.execute();
                            }}
                            selectedAdmin={selectedAdmin}
                        />
                        <Modal
                            isOpen={!!linkSentMessage}
                            hide={() => setLinkSentMessage('')}
                            onConfirm={() => setLinkSentMessage('')}
                            hideCancel
                            content={
                                <InfoText text={linkSentMessage} />
                            }
                            confirmButtonText={'Okay'}
                            showIcon={false}
                            title={'Success'}
                        />
                    </>
                    )
                :
                    getAdminList.error ?
                        <ErrorMessage text={getAdminList.error} /> :
                        <LoadingSpinner />
                }
            </PageContainer>
            {resendInvite.error && <ErrorMessage text={resendInvite.error} />}
        </>
    );
};

export const AddButtonWrapper = styled.div`
  text-align: right;
`;
