import { NonAdmin } from 'shared/types/User';
import { CompletionDates } from 'shared/types/Result';

export type AdaptedUser = NonAdmin & {
    surveyDates: string[];
    pairSurveyDates: string[];
    lastDownloaded: string | null;
    pairLastDownloaded: string | null;
}

export const adaptDatesToUsers = (userList: NonAdmin[], dateList: CompletionDates): AdaptedUser[] => {
    const adaptedUsers = userList.map(patient => {
        return {
            ...patient,
            surveyDates: dateList[patient.username]?.completionDates ?? [],
            pairSurveyDates: dateList[patient.pairUsername]?.completionDates ?? [],
            lastDownloaded: dateList[patient.username]?.lastDownloadDate,
            pairLastDownloaded: dateList[patient.pairUsername]?.lastDownloadDate,
        }
    });
    return adaptedUsers;
}