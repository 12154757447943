import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { adminApiManager } from '../network/apiManager';
import { Admin } from 'shared/types/User';

type UserContextType = {
    currentLogin: Admin | null | undefined;
    userLoading: boolean;
    setUserLoading: Dispatch<SetStateAction<boolean>>;
    setCurrentLogin: Dispatch<SetStateAction<Admin | null | undefined>>;
}
export const UserContext = createContext<UserContextType>({
    currentLogin: undefined,
    userLoading: false,
    setUserLoading: () => {},
    setCurrentLogin: () => {},
});

export default function UserContextProvider({ children }: { children: ReactNode }) {
    const [userLoading, setUserLoading] = useState<boolean>(true);
    const [currentLogin, setCurrentLogin] = useState<Admin | null | undefined>(undefined);

    useEffect(() => {
        console.log('current user update', currentLogin);
        //login/refresh logic
        (async () => {
            try {
                if (currentLogin) {
                    setUserLoading(false);
                } else if (currentLogin === undefined) {
                    await adminApiManager.Auth.refresh({ setCurrentLogin });
                    setUserLoading(false);
                } else {
                    setUserLoading(false);
                }
            } catch (error) {
                console.log(error);
                setCurrentLogin(null);
                setUserLoading(false);
            }
        })();
    }, [currentLogin]);

    return (
        <UserContext.Provider
            value={{
                currentLogin,
                userLoading,
                setUserLoading,
                setCurrentLogin
            }}
        >
            {children}
        </UserContext.Provider>
    )
}