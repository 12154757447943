import React, { useEffect, useState } from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { PageContainer } from '../../components/Wrappers';
import { ErrorMessage, InfoText, PageTitle } from 'shared/components/Text';
import { BaseButton } from 'shared/components/Buttons';
import { DateSelect, DateSelectLabel, DateSelectWrapper } from '../../components/DateSelect';
import styled from 'styled-components';
import { useAsync } from 'shared/hooks/useAsync';
import { adminApiManager } from '../../network/apiManager';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import LoadingSpinner, { MiniLoadingSpinner } from 'shared/components/Loader';
import { handleDownloadFile } from 'shared/utils/handleDownloadFile';

export const Data: RFC = () => {
    const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null);
    const [selectedToDate, setSelectedToDate] = useState<Date | null>(null);
    const [surveyId, setSurveyId] = useState<string>('');

    useEffect(() => {
        //get survey id on load
        getSurvey.execute();
    }, [])

    const onLoad = async () => {
        try {
            //TODO: may need to display list as select in future if multiple surveys are added
            const surveyList = await adminApiManager.Surveys.getSurveys();
            setSurveyId(surveyList[0].id);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const onSubmit = async () => {
        try {
            if (selectedFromDate && selectedToDate && surveyId) {
                //convert dates to UTC and account for time of day
                const start = startOfDay(selectedFromDate).toISOString();
                const end = endOfDay(selectedToDate).toISOString();

                //send request
                const getCsvResponse = await adminApiManager.Results.getCsvResults({ surveyId, start, end });

                //create url from response blob, generate link, and auto-click to download
                await handleDownloadFile(getCsvResponse, 'csv');
            } else {
                throw new Error();
            }
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const downloadCsv = useAsync<void>(onSubmit, "Error getting results. Please check your connection and try again.");
    const getSurvey = useAsync<void>(onLoad, "Error getting survey data. Please check your connection and try again.");

    return (
        <PageContainer>
            {!!surveyId ?
                <>
                    <PageTitle text={'Export Survey Results'} textAlign={'left'} margin={'10px'} />
                    <PageContentWrapper>
                        <DateSelectLabel>Select Date Range: </DateSelectLabel>
                        <DateSelectWrapper>
                            <InfoText text={'from'} width={'40px'} textAlign={'right'} />

                            <DateSelect
                                selected={selectedFromDate}
                                filterDate={(date) => {
                                    //filter out future dates and dates past selectedToDate
                                    const futureDate = date < new Date();
                                    const outOfRangeDate = selectedToDate ? date < new Date(selectedToDate) : true;
                                    return futureDate && outOfRangeDate;
                                }}
                                onChange={(val: Date | null) => {
                                    setSelectedFromDate(val)
                                }}
                                withPortal
                            />
                        </DateSelectWrapper>
                        <DateSelectWrapper id={"selectedTo"}>
                            <InfoText text={'to'} width={'40px'} textAlign={'right'} />
                            <DateSelect
                                selected={selectedToDate}
                                filterDate={(date) => {
                                    //filter out future dates and dates prior to selectedFromDate
                                    const futureDate = date < new Date();
                                    const outOfRangeDate = selectedFromDate ? date > new Date(selectedFromDate) : true;
                                    return futureDate && outOfRangeDate;
                                }}
                                onChange={(val: Date | null) => {
                                    setSelectedToDate(val)
                                }}
                                withPortal
                            />
                        </DateSelectWrapper>
                        <BaseButton
                            text={!downloadCsv.pending ? 'Download CSV' : <MiniLoadingSpinner />}
                            width={'auto'}
                            fontSize={'16px'}
                            padding={'8px 10px'}
                            margin={'10px'}
                            disabled={!!downloadCsv.pending || !selectedToDate || !selectedFromDate}
                            onClick={downloadCsv.execute}
                        />
                    </PageContentWrapper>
                    {!!downloadCsv.error && <ErrorMessage text={downloadCsv.error} />}
                </>
            :
                <>
                    {!!getSurvey.pending ?
                        <LoadingSpinner />
                    :
                        <ErrorMessage text={getSurvey.error ?? 'Error fetching your data. Please check your connection and try again.'} />
                    }
                </>
            }
        </PageContainer>
    )
};

const PageContentWrapper = styled.div`
    display: flex;
    margin: 25px auto;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
`;

