import { MenuContent, MenuDivider, MenuItem } from 'shared/components/Nav';
import { StyledExternalLink, StyledInternalLink } from 'shared/components/Links';
import IconManager, { IconType } from 'shared/components/IconManager';
import { LogoutButtonLink } from 'shared/components/Buttons';
import React from 'react';
import { Theming } from '../theming';
import { AuthRouteNames } from '../navigation/routeNames';
import { isProd } from '../../environmentConfig';

type MenuProps = {
    showHiddenLinks: boolean;
    onLogout: () => void;
    closeMenu: () => void;
};

export const NavMenu = ({ showHiddenLinks, onLogout, closeMenu }: MenuProps) => {
    return (
        <MenuContent>
            {/*hide nav links when logged out*/}
            {showHiddenLinks && (
                <>
                    <StyledInternalLink
                        to={AuthRouteNames.Profile}
                        color={Theming.primaryButtonColor}
                        $removeUnderline={true}
                    >
                        <MenuItem onClick={() => closeMenu()}>
                            <IconManager type={IconType.PROFILE} stroke={Theming.primaryIconColor} />
                            &nbsp; Profile
                        </MenuItem>
                    </StyledInternalLink>
                    <MenuDivider />
                </>
            )}
            {/*hide report a bug feature on production*/}
            {!isProd && (
                <>
                    <StyledExternalLink
                        href={'https://forms.clickup.com/f/80c80-5026/VJPFXIDC63KJMO37MV'}
                        target={'blank'}
                        color={Theming.primaryButtonColor}
                        removeUnderline
                        onClick={() => closeMenu()}
                    >
                        <MenuItem>
                            <IconManager
                                type={IconType.REPORT_BUG}
                                fill={Theming.primaryIconColor}
                                stroke={Theming.primaryIconColor}
                            />
                            &nbsp; Report a Bug
                        </MenuItem>
                    </StyledExternalLink>
                    <MenuDivider />
                </>
            )}
            {/*hide nav links when logged out*/}
            {showHiddenLinks && (
                <>
                    <MenuItem>
                        <LogoutButtonLink
                            text={'Sign Out'}
                            onClick={() => {
                                onLogout();
                                closeMenu();
                            }}
                        />
                    </MenuItem>
                </>
            )}
        </MenuContent>
    );
};
