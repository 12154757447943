import React, { useContext, useEffect, useState } from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { PageContainer } from '../../components/Wrappers';
import { LabeledTextInput } from 'shared/components/Input';
import { BaseButton } from 'shared/components/Buttons';
import styled from 'styled-components';
import { Theming } from '../../theming';
import { ErrorMessage, PageTitle } from 'shared/components/Text';
import { useAsync } from 'shared/hooks/useAsync';
import { ChangePassword } from './ChangePassword';
import { UserContext } from '../../context/UserContext';
import { adminApiManager } from '../../network/apiManager';
import { MiniLoadingSpinner } from 'shared/components/Loader';

type FormState = {
    firstName: string
    lastName: string
    email: string
    institution: string
    isEditing: boolean
    error: FormError | null
}

enum FormError {
    EMPTY_FIELDS = "Please fill out all fields.",
}

export const Profile: RFC = () => {
    const { currentLogin, setCurrentLogin, setUserLoading } = useContext(UserContext);

    const initialFormState = {
        firstName: currentLogin?.firstName ?? '',
        lastName: currentLogin?.lastName ?? '',
        email: currentLogin?.username ?? '',
        institution: currentLogin?.institution ?? '',
        isEditing: false,
        error: null
    }

    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        //when current user gets updated, update form data
        setFormState(initialFormState)
    }, [currentLogin])

    const onSubmit = async () => {
        try {
            const updatedData = {
                firstName: formState.firstName,
                lastName: formState.lastName,
                // email: formState.email,
                institution: formState.institution
            }
            //prevent unauthorized screen while user updates
            setUserLoading(true);
            await adminApiManager.Users.updateCurrentUser(updatedData, setCurrentLogin);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const validateForm = () => {
        //clear any errors
        if (formState.error) {
            setFormState(prevState => {
                return {...prevState, error: null}
            })
        }
        if (saveProfile.error) {
            saveProfile.clearError();
        }

        //check that all form fields have been filled out
        if (formState.firstName && formState.lastName && formState.email && formState.institution) {
            //if valid, disable editing and submit data
            setFormState(prevState => {
                return {
                    ...prevState,
                    isEditing: false
                }
            })
            saveProfile.execute();
        } else {
            setFormState(prevState => {
                return {...prevState, error: FormError.EMPTY_FIELDS}
            })
        }
    }

    const saveProfile = useAsync<void>(onSubmit, "Error saving your changes. Please check your connection and try again.")
    
    return (
        <PageContainer>
            <ProfileWrapper>
                <PageTitle
                    text={'Profile'}
                    textAlign={'left'}
                    margin={'20px'}
                />
                <LabeledTextInput
                    label={'First Name'}
                    placeholder={formState.firstName}
                    value={formState.firstName}
                    width={'60%'}
                    disabled={!formState.isEditing}
                    onChange={e => {
                        //saving value to variable to prevent synthetic event error
                        const newValue = e.target.value;
                        setFormState(prevState => {
                            return { ...prevState, firstName: newValue };
                        });
                    }}
                    required
                    error={!!formState.error && !formState.firstName ? formState.error : ''}
                />
                <LabeledTextInput
                    label={'Last Name'}
                    placeholder={formState.lastName}
                    value={formState.lastName}
                    width={'60%'}
                    disabled={!formState.isEditing}
                    onChange={e => {
                        //saving value to variable to prevent synthetic event error
                        const newValue = e.target.value;
                        setFormState(prevState => {
                            return { ...prevState, lastName: newValue };
                        });
                    }}
                    required
                    error={!!formState.error && !formState.lastName ? formState.error : ''}
                />
                <LabeledTextInput
                    label={'Email: '}
                    placeholder={formState.email}
                    value={formState.email}
                    width={'60%'}
                    disabled={true} //admins not currently allowed to change email address
                    onChange={e => {
                        //saving value to variable to prevent synthetic event error
                        const newValue = e.target.value;
                        setFormState(prevState => {
                            return { ...prevState, email: newValue };
                        });
                    }}
                    required
                    error={!!formState.error && !formState.email ? formState.error : ''}
                />
                <LabeledTextInput
                    label={'Institution: '}
                    placeholder={formState.institution}
                    value={formState.institution}
                    width={'60%'}
                    disabled={!formState.isEditing}
                    onChange={e => {
                        //saving value to variable to prevent synthetic event error
                        const newValue = e.target.value;
                        setFormState(prevState => {
                            return { ...prevState, institution: newValue };
                        });
                    }}
                    required
                    error={!!formState.error && !formState.institution ? formState.error : ''}
                />

                {saveProfile.error && <ErrorMessage text={saveProfile.error} />}

                <ButtonWrapper>
                    <BaseButton
                        text={formState.isEditing? 'Save' : !saveProfile.pending ? 'Edit Profile' : <MiniLoadingSpinner />}
                        width={'90%'}
                        onClick={() => {
                            if (formState.isEditing) {
                                validateForm();
                            } else {
                                setFormState(prevState => {
                                    return {
                                        ...prevState,
                                        isEditing: true
                                    }
                                })
                            }
                        }}
                    />
                    <BaseButton
                        text={'Reset Password'}
                        width={'90%'}
                        isPrimary={false}
                        onClick={() => setShowModal(true)}
                    />
                </ButtonWrapper>
            </ProfileWrapper>

            {/*modal pop up */}
            <ChangePassword
                isOpen={showModal}
                hide={() => setShowModal(false)}
                onConfirm={() => setShowModal(false)}
            />
        </PageContainer>
    )
};



const ProfileWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        width: 100%;
    }
`;

const ButtonWrapper = styled.div`
    text-align: center;
    margin: 20px auto;
`;