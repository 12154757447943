import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Background } from 'shared/components/Wrappers';
import { AuthRouteNames, NonAuthRouteNames } from './navigation/routeNames';
import { Login } from './pages/auth/Login';
import { AuthRoute, NonAuthRoute } from './navigation/RouteComponents';
import { UserList } from './pages/users/UserList';
import { AdminList } from './pages/admins/AdminList';
import { Data } from './pages/data/Data';
import { Profile } from './pages/profile/Profile';
import { NavHeader } from './pages/NavHeader';
import './App.css';
import { RedirectToStart } from './pages/RedirectToStart';
import { MainContainer } from './components/Wrappers';
import { Unauthorized } from 'shared/components/Unauthorized';
import { PairResults } from './pages/users/PairResults';
import { UserContext } from './context/UserContext';
import LoadingSpinner from 'shared/components/Loader';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { NotFound } from 'shared/components/NotFound';
import { CreateAccount } from './pages/auth/CreateAccount';
import { ResetPassword } from './pages/auth/ResetPassword';
import { IdleTimeoutWrapper } from 'shared/components/IdleTimeoutWrapper';
import { minutesToMS } from 'shared/utils/minutesToMS';
import { adminApiManager } from './network/apiManager';

export default function App() {
    const { currentLogin, userLoading, setCurrentLogin } = useContext(UserContext);
    return (
        <>
            <Background />
            <Router>
                <NavHeader />
                <MainContainer>
                    {!currentLogin ? (
                        <>
                            {!userLoading ? (
                                <Switch>
                                    <NonAuthRoute component={<Login />} path={NonAuthRouteNames.Login} exact />
                                    <NonAuthRoute component={<ForgotPassword />} path={NonAuthRouteNames.ForgotPassword} />
                                    <NonAuthRoute component={<CreateAccount />} path={NonAuthRouteNames.CreateAccount} />
                                    <NonAuthRoute component={<ResetPassword />} path={NonAuthRouteNames.ResetPassword} />
                                    <NonAuthRoute component={<Unauthorized />} />
                                </Switch>
                            ) : (
                                <NonAuthRoute component={<LoadingSpinner />} />
                            )}
                        </>
                    ) : (
                        //auth switch router wrapped in timeout functionality to logout user after period of inactivity
                        <IdleTimeoutWrapper
                            onLogout={async () => await adminApiManager.Auth.logout(setCurrentLogin)}
                            logoutPath={NonAuthRouteNames.Login}
                            onReset={async () => await adminApiManager.resetTokenExpiration()}
                            countdownTime={minutesToMS(15)}
                        >
                            <Switch>
                                <NonAuthRoute component={<RedirectToStart />} path={AuthRouteNames.RedirectToStart} exact />
                                <AuthRoute component={<UserList />} path={AuthRouteNames.UserList} exact />
                                <AuthRoute component={<PairResults />} path={AuthRouteNames.PairResults} />
                                <AuthRoute component={<AdminList />} path={AuthRouteNames.AdminList} />
                                <AuthRoute component={<Data />} path={AuthRouteNames.Data} />
                                <AuthRoute component={<Profile />} path={AuthRouteNames.Profile} />
                                <AuthRoute component={<NotFound />} />
                            </Switch>
                        </IdleTimeoutWrapper>
                    )}
                </MainContainer>
            </Router>
        </>
    );
}
