import { ApiManager } from 'shared/network/apiManager';
import { accessTokenKey, refreshTokenKey, tokenKey } from '../constants/localStorageKeys';
import { environmentConfig } from '../../environmentConfig';
import { LoginType } from 'shared/types/api/auth/LoginWithPassword';
import { minutesToMS } from 'shared/utils/minutesToMS';

class AdminApiManager extends ApiManager {
    async getRefreshToken(): Promise<string | null> {
        //get token from local storage
        const tokenString = await localStorage.getItem(refreshTokenKey);

        //if no token found, return null
        if (!tokenString) {
            return null;
        } else {
            const tokenWithExpiry = JSON.parse(tokenString);
            const now = new Date();

            //if token is expired, clear all tokens and survey status from local storage and return null
            if (now.getTime() > tokenWithExpiry.expiry) {
                await this.clearTokens();
                return null;
            } else {
                return tokenWithExpiry.value;
            }
        }
    }

    async setRefreshToken(newToken: string): Promise<void> {
        //if clearing token, no need to set expiration
        if (!newToken) {
            await localStorage.setItem(refreshTokenKey, newToken);
        } else {
            //set expiration time on token
            const now = new Date();
            const tokenWithExpiry = {
                value: newToken,
                expiry: now.getTime() +  minutesToMS(16)
            }
            //stringify token with expiry and set to local storage
            await localStorage.setItem(refreshTokenKey, JSON.stringify(tokenWithExpiry));
        }
    }

    async removeRefreshToken(): Promise<void> {
        await localStorage.removeItem(refreshTokenKey);
    }

    async getToken(): Promise<string | null> {
        return await localStorage.getItem(tokenKey);
    }

    async setToken(newToken: string): Promise<void> {
        await localStorage.setItem(tokenKey, newToken);
    }

    async removeToken(): Promise<void> {
        await localStorage.removeItem(tokenKey);
    }

    async getAccessToken(): Promise<string | null> {
        return await localStorage.getItem(accessTokenKey);
    }

    async setAccessToken(newToken: string): Promise<void> {
        await localStorage.setItem(accessTokenKey, newToken);
    }

    async removeAccessToken(): Promise<void> {
        await localStorage.removeItem(accessTokenKey);
    }
}

export const adminApiManager = new AdminApiManager(environmentConfig.apiUrl, LoginType.ADMIN)