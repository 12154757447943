import dateFNSFormat from 'date-fns/format';

export const adaptDatesToSelectOption = (dates: string[]) => {
    const adaptedDates = dates.map(date => {
        return {
            label: dateFNSFormat(new Date(date), 'MM/dd/yyyy'),
            value: date
        }
    });
    return adaptedDates;
}