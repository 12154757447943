import React, { useEffect, useState } from 'react';
import { Modal, ModalProps } from 'shared/components/Modal';
import { LabeledTextInput } from 'shared/components/Input';
import { ModalContentWrapper } from '../../components/Wrappers';
import { useAsync } from 'shared/hooks/useAsync';
import { adminApiManager } from '../../network/apiManager';
import { ErrorMessage } from 'shared/components/Text';
import { removeWhiteSpace } from 'shared/utils/removeWhiteSpace';

type FormState = {
    patientUsername: string
    partnerUsername: string
    error: FormError | null
}

enum FormError {
    EMPTY_FIELDS = "Please fill out all fields."
}

export const AddNewPair = ({ isOpen, hide, onConfirm }: ModalProps) => {
    const initialFormState = {
        patientUsername: '',
        partnerUsername: '',
        error: null
    }
    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [surveyId, setSurveyId] = useState<string>('');

    useEffect(() => {
        //get survey id on load
        getSurvey.execute();
    }, []);

    const onLoad = async () => {
        try {
            //get survey list and set first survey id to state
            const surveyList = await adminApiManager.Surveys.getSurveys();
            // TODO: will need to add input for survey id or setup select if multiple surveys get created in future
            setSurveyId(surveyList[0].id);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const onSubmit = async () => {
        try {
            const pairToCreate = {
                patientUsername: formState.patientUsername,
                partnerUsername: formState.partnerUsername,
                surveyId
            };
            const createPairResponse = await adminApiManager.Users.createPair(pairToCreate);

            if (createPairResponse) {
                //clear form, close modal, and refresh user list on success
                setFormState(initialFormState);
                onConfirm();
            }
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const validateForm = () => {
        //clear any previous errors
        if (formState.error) {
            setFormState(prevState => {
                return { ...prevState, error: null }
            })
        }
        if (createPair.error) {
            createPair.clearError();
        }

        //check that all form fields were filled out
        if (!formState.partnerUsername || !formState.patientUsername) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.EMPTY_FIELDS }
            });
        } else {
            //if valid, submit data
            createPair.execute();
        }
    }

    const createPair = useAsync<void>(onSubmit, "Error creating users. Please check your connection and try again.");
    const getSurvey = useAsync<void>(onLoad, "Error loading data. Please check your connection and try again.");

    return (
        <Modal
            isOpen={isOpen}
            hide={() => {
                //clear any errors on close
                if (createPair.error) {
                    createPair.clearError();
                }
                //clear any unsaved form data or form errors
                setFormState(initialFormState);
                //close modal
                hide()
            }}
            onConfirm={validateForm}
            showIcon={false}
            title={'Add New Pair'}
            confirmButtonText={'Create Pair'}
            cancelButtonText={'Cancel'}
            content={
                <>
                <ModalContentWrapper>
                    <LabeledTextInput
                        label={'Patient: '}
                        placeholder={'Enter patient username'}
                        value={formState.patientUsername}
                        width={'60%'}
                        onChange={e => {
                            //saving value to variable to prevent synthetic event error
                            const newValue = removeWhiteSpace(e.target.value);
                            setFormState(prevState => {
                                return { ...prevState, patientUsername: newValue };
                            });
                        }}
                        required
                        error={!!formState.error && !formState.patientUsername ? formState.error : ''}
                    />
                    <LabeledTextInput
                        label={'Caregiver: '}
                        placeholder={'Enter caregiver username'}
                        value={formState.partnerUsername}
                        width={'60%'}
                        onChange={e => {
                            //saving value to variable to prevent synthetic event error
                            const newValue = removeWhiteSpace(e.target.value);
                            setFormState(prevState => {
                                return { ...prevState, partnerUsername: newValue };
                            });
                        }}
                        required
                        error={!!formState.error && !formState.partnerUsername ? formState.error : ''}
                    />

                    {createPair.error && <ErrorMessage text={createPair.error} margin={'10px auto 25px'} />}
                </ModalContentWrapper>
                </>
            }
        />
    )
}



