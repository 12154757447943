import React, { useEffect, useState } from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { PageContainer } from '../../components/Wrappers';
import { Theming } from '../../theming';
import { StyledInternalLink } from 'shared/components/Links';
import IconManager, { IconType } from 'shared/components/IconManager';
import {
    ColumnTitle,
    LinkToModal,
    TableBody,
    TableContainer,
    TableHeader,
    TableItem,
    TableRow,
} from '../../components/Table';
import { BaseButton } from 'shared/components/Buttons';
import { AddButtonWrapper } from '../admins/AdminList';
import { AddNewPair } from './AddNewPair';
import { useAsync } from 'shared/hooks/useAsync';
import { adminApiManager } from '../../network/apiManager';
import LoadingSpinner from 'shared/components/Loader';
import { ErrorMessage } from 'shared/components/Text';
import { ManageUserPasswords } from './ManageUserPasswords';
import { adaptDatesToUsers, AdaptedUser } from '../../adapters/userAdapters';
import dateFNSFormat from 'date-fns/format';

export const UserList: RFC = () => {
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showManageModal, setShowManageModal] = useState<boolean>(false);
    const [userList, setUserList] = useState<AdaptedUser[] | null>(null);
    const [selectedPatient, setSelectedPatient] = useState<AdaptedUser | null>(null);

    useEffect(() => {
        //fetch users on load
        getUsers.execute();
        
        //clear errors on unmount
        return getUsers.clearError();
    }, []);

    const getUsersAsync = async () => {
        try {
            //fetch user list and completed survey dates
            const getUsersResponse = await adminApiManager.Users.getPairs({});
            const getDatesResponse = await adminApiManager.Results.getDates({});
            if (getUsersResponse && getDatesResponse) {
                //add completed survey dates to user data
                const adaptedUsers = adaptDatesToUsers(getUsersResponse, getDatesResponse)
                //set users in state
                setUserList(adaptedUsers);
            }
        } catch(error) {
            console.log(error);
            throw error;
        }
    }
    
    const getUsers = useAsync<void>(getUsersAsync, "Could not find users. Please check your connection and try again.")

    return (
        <>
            <AddButtonWrapper>
                <BaseButton
                    text={"Add New Pair"}
                    width={"auto"}
                    onClick={() => setShowAddModal(true)}
                />
            </AddButtonWrapper>
            <PageContainer>
                {!!userList ?
                    <TableContainer>
                        <TableHeader>
                            <TableRow>
                                <ColumnTitle>Patient ID</ColumnTitle>
                                <ColumnTitle>Caregiver ID</ColumnTitle>
                                <ColumnTitle>Manage Passwords</ColumnTitle>
                                <ColumnTitle>Patient Completed?</ColumnTitle>
                                <ColumnTitle>Caregiver Completed?</ColumnTitle>
                                <ColumnTitle>Survey Results</ColumnTitle>
                                <ColumnTitle>Patient Last Downloaded</ColumnTitle>
                                <ColumnTitle>Caregiver Last Downloaded</ColumnTitle>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {userList.map((patient, index) => {
                                return (
                                    <TableRow key={index} rowIndex={index}>
                                        <TableItem>{patient.username}</TableItem>
                                        <TableItem>{patient.pairUsername}</TableItem>
                                        <TableItem>
                                            <LinkToModal
                                                onClick={() => {
                                                setSelectedPatient(patient);
                                                setShowManageModal(true);
                                            }}>
                                                <IconManager
                                                    type={IconType.EXTERNAL_LINK}
                                                    stroke={Theming.secondaryButtonColor}
                                                    size={16}
                                                />
                                            </LinkToModal>
                                        </TableItem>
                                        <TableItem>
                                            {patient.surveyDates.length ? dateFNSFormat(new Date(patient.surveyDates[0]), 'MM/dd/yyyy') : 'N/A'}
                                        </TableItem>
                                        <TableItem>
                                            {patient.pairSurveyDates.length ? dateFNSFormat(new Date(patient.pairSurveyDates[0]), 'MM/dd/yyyy') : 'N/A'}
                                        </TableItem>
                                        <TableItem>
                                            <StyledInternalLink to={`/users/results?patient=${patient.username}&caregiver=${patient.pairUsername}`}>
                                                View Results
                                            </StyledInternalLink>
                                        </TableItem>
                                        <TableItem>
                                            {patient.lastDownloaded ? dateFNSFormat(new Date(patient.lastDownloaded), 'MM/dd/yyyy') : 'N/A'}
                                        </TableItem>
                                        <TableItem>
                                            {patient.pairLastDownloaded ? dateFNSFormat(new Date(patient.pairLastDownloaded), 'MM/dd/yyyy') : 'N/A'}
                                        </TableItem>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </TableContainer>
                :
                    !!getUsers.pending ? <LoadingSpinner /> :
                        <ErrorMessage text={getUsers.error ?? 'No results found'} />
                }
            </PageContainer>

            {/*modal pop ups*/}
            <AddNewPair
                isOpen={showAddModal}
                hide={() => setShowAddModal(false)}
                onConfirm={() => {
                    //close modal and refresh user list
                    setShowAddModal(false);
                    getUsers.execute();
                }}
            />
            <ManageUserPasswords
                isOpen={showManageModal}
                hide={() => setShowManageModal(false)}
                onConfirm={() => setShowManageModal(false)}
                selectedPatient={selectedPatient}
            />
        </>
    );
};
