import React from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';

type CustomSelectProps = {
    label: string;
    options: SelectOption[];
    // onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onChange: (newVal: any) => void //passing the event value from the select
}



type SelectOption = {
    label: string
    value: any
}

export function Select({ label, options, onChange }: CustomSelectProps) {
    return (
        <SelectWrapper>
            <SelectLabel>{label}</SelectLabel>
            <StyledSelect onChange={(e) => onChange(e.currentTarget.value)}>
                {options.map((option, index) => {
                    return (
                        <SelectOption key={index} value={option.value}>
                            {option.label}
                        </SelectOption>
                    );
                })}
            </StyledSelect>
        </SelectWrapper>
    );
}

const StyledSelect = styled.select`
    font-family: ${Theming.regularFont};
    width: 100%;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        margin: 10px auto;
    }
`;

export const SelectLabel = styled.p`
    display: block;
    text-align: center;
    font-family: ${Theming.regularFont};
    color: ${Theming.secondaryTextColor};
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        margin: 5px auto;
    }
`;

const SelectOption = styled.option``;
const SelectWrapper = styled.div`
    margin: 0 20px;
    min-width: 100px;
`;


