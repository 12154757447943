import React, { useContext } from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { SignInRequest } from 'shared/types/Auth';
import { useAsync } from 'shared/hooks/useAsync';
import LoadingSpinner from 'shared/components/Loader';
import { LoginForm } from './components/LoginForm';
import { UserContext } from '../../context/UserContext';
import { adminApiManager } from '../../network/apiManager';
import { ErrorMessage } from 'shared/components/Text';

export const Login: RFC = () => {
    const { userLoading, setCurrentLogin } = useContext(UserContext);

    const onSubmit = async (loginInfo: SignInRequest) => {
        //clear any errors before submitting
        login.clearError();
        try {
            //submit login to server and set user in context
            await adminApiManager.Auth.loginWithPassword(loginInfo, setCurrentLogin)
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    const login = useAsync<void>(
        loginInfo => onSubmit(loginInfo),
        'Error logging in. Check your username and password.'
    );

    return (
        <>
            {!userLoading && !login.pending ? (
                <>
                    <LoginForm onSubmit={login.execute} />
                    {login.error && <ErrorMessage text={login.error} />}
                </>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};
