import { Theming } from '../theming';
import React from 'react';
import styled from 'styled-components';

export const TableContainer = styled.table`
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
`;

export const TableHeader = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr<{ rowIndex?: number; isLastRow?: boolean; disabled?: boolean }>`
    background-color: ${({ rowIndex }) =>
        !rowIndex || !(rowIndex % 2) ? 'transparent' : Theming.primaryBackgroundColor};
    border-bottom: ${({ isLastRow }) => (isLastRow ? `1px ${Theming.separatorColor} solid` : 'none')};
    color: ${({ disabled }) => (disabled ? Theming.placeholderTextColor : Theming.primaryTextColor)};
    font-style: ${({ disabled }) => (disabled ? 'italic' : 'normal')};
`;

export const ColumnTitle = styled.th`
    padding: 5px 5px 10px;
    margin: 5px 0;
    border-bottom: 1px ${Theming.separatorColor} solid;
    color: ${Theming.titleTextColor};
`;

export const RowTitle = styled.th`
    padding: 5px 5px 10px;
    margin: 5px 0;
    color: ${Theming.titleTextColor};
    border-right: 1px ${Theming.separatorColor} solid;
    border-bottom: 1px ${Theming.separatorColor} solid;
    white-space: pre-wrap;
`;

export const TableItem = styled.td`
    padding: 10px 5px 5px;
    color: inherit;
`;

export const LinkToModal = styled.div<{ disabled?: boolean }>`
    display: inline-block;
    :hover& {
        cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
    }
`;
