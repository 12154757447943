import React, { useContext, useEffect, useRef, useState } from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useOutsideAlerter } from 'shared/hooks/useOutsideAlerter';
import IconManager, { IconType } from 'shared/components/IconManager';
import { Theming } from '../theming';
import { Logo, LogoSpacer, MenuToggle, MenuWrapper } from 'shared/components/Nav';
import { NavMenu } from '../components/NavMenu';
import styled from 'styled-components';
import { AuthRouteNames, NonAuthRouteNames } from '../navigation/routeNames';
import { sharedTheming } from 'shared/sharedTheming';
import { NavBar, NavLink, NavLinks } from '../components/NavBar';
import PacesLogo from '../../../shared/assets/logos/pacesLogoTransparent.png';
import { adminApiManager } from '../network/apiManager';
import { useAsync } from 'shared/hooks/useAsync';
import { UserContext } from '../context/UserContext';
import { isProd } from '../../environmentConfig';

export const NavHeader: RFC = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { currentLogin, setCurrentLogin } = useContext(UserContext);

    const [showHiddenLinks, setShowHiddenLinks] = useState<boolean>(!!currentLogin);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    //to close menu when clicking anywhere else in window
    const wrapperRef = useRef(null);
    useOutsideAlerter({ ref: wrapperRef, callback: () => setShowMenu(false) });

    useEffect(() => {
        // toggle button visibility when user signs in/out
        if (currentLogin && !showHiddenLinks) {
            setShowHiddenLinks(true);
        } else if (!currentLogin && showHiddenLinks) {
            setShowHiddenLinks(false);
        }
    }, [currentLogin]);

    const logoutAsync = async () => {
        try {
            console.log('logging out');
            await adminApiManager.Auth.logout(setCurrentLogin);
        } catch (error) {
            console.log(error);
            throw error;
        }
        history.push(NonAuthRouteNames.Login);
    };

    const logout = useAsync<void>(logoutAsync);

    return (
        <>
            <NavBar>
                <LogoWrapper>
                    <Link to={'/'}>
                        <Logo src={PacesLogo} />
                    </Link>
                </LogoWrapper>
                {/*hide nav links when logged out*/}
                {showHiddenLinks && (
                    <NavLinks>
                        <NavLink
                            isActive={pathname === AuthRouteNames.UserList}
                            path={AuthRouteNames.UserList}
                            text={'Users'}
                        />
                        <NavLink
                            isActive={pathname === AuthRouteNames.AdminList}
                            path={AuthRouteNames.AdminList}
                            text={'Admins'}
                        />
                        <NavLink
                            isActive={pathname === AuthRouteNames.Data}
                            path={AuthRouteNames.Data}
                            text={'Data'}
                        />
                    </NavLinks>
                )}
                {/*hide empty menu on prod logged out screen*/}
                {(!isProd || !!currentLogin) && (
                    <MenuWrapper ref={wrapperRef}>
                        <MenuToggle>
                            <IconManager
                                type={IconType.HAMBURGER}
                                stroke={Theming.primaryIconColor}
                                size={28}
                                onClick={() => setShowMenu(prevState => !prevState)}
                            />
                        </MenuToggle>
                        {showMenu && (
                            <NavMenu
                                showHiddenLinks={showHiddenLinks}
                                onLogout={logout.execute}
                                closeMenu={() => setShowMenu(false)}
                            />
                        )}
                    </MenuWrapper>
                )}
            </NavBar>
        </>
    );
};

const LogoWrapper = styled.div`
    width: 180px;
    padding: 0 10px;
    @media (max-width: ${sharedTheming.breakpoints.smallScreen}px) {
        width: 25%;
        max-width: 180px;
    }
`;


