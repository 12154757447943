import React from 'react';
import { Theming } from '../../../theming';
import styled from 'styled-components';

type SurveyAnswerProps = {
    size: number;
    text: string;
    isPrimary?: boolean;
    active?: boolean;
};

export const SurveyAnswer = ({ size, text, isPrimary = true, active = true }: SurveyAnswerProps) => {
    return (
        <StyledSurveyAnswer
            color={
                !active
                    ? Theming.iconColorDisabled
                    : isPrimary
                    ? Theming.primaryIconColor
                    : Theming.secondaryIconColor
            }
            size={size}
        >
            {text ?? 'N/A'}
        </StyledSurveyAnswer>
    );
};

const StyledSurveyAnswer = styled.p<{ color: string; size: number }>`
    text-align: center;
    font-size: ${({ size }) => size}px;
    font-family: ${Theming.regularFont};
    font-weight: bold;
    color: ${({ color }) => color};
    margin: 0 auto;
`;
