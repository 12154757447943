import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styled from 'styled-components';
import { Theming } from '../theming';
import DatePicker from 'react-datepicker';

export const DateSelectWrapper = styled.div`
  display: flex;
`;

export const DateSelectLabel = styled.div`
    display: inline-block;
    margin: 10px;
    text-align: left;
    font-family: ${Theming.regularFont};
    color: ${Theming.secondaryTextColor};
    font-weight: bold;
`;

export const DateSelect = styled(DatePicker)`
    margin: 10px;
    padding: 5px;
    font-family: ${Theming.regularFont};
    font-size: 14px;
    font-color: ${Theming.primaryTextColor};
    border: 1px solid ${Theming.placeholderTextColor};
    border-radius: 5px;
`;