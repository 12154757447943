import React, { useState } from 'react';
import { Modal, ModalProps } from 'shared/components/Modal';
import { useAsync } from 'shared/hooks/useAsync';
import { ErrorMessage, InfoText } from 'shared/components/Text';
import { adminApiManager } from '../../network/apiManager';
import { LabeledTextInput } from 'shared/components/Input';
import { removeWhiteSpace } from 'shared/utils/removeWhiteSpace';

type FormState = {
    oldPassword: string
    newPassword: string
    newPasswordConfirm: string
    error: FormError | null
    success: boolean
}

enum FormError {
    EMPTY_FIELDS = "Please fill out all fields.",
    INVALID_LENGTH = "Password length must be at least 8 characters.",
    MISMATCHED_PASSWORDS = "New password fields must match."
}

export const ChangePassword = ({ isOpen, hide, onConfirm }: ModalProps) => {
    const initialFormState = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        error: null,
        success: false
    }

    const [formState, setFormState] = useState<FormState>(initialFormState);

    const onSubmit = async () => {
        try {
            //send request
            await adminApiManager.Auth.changePassword({
                oldPassword: formState.oldPassword,
                newPassword: formState.newPassword
            })
            //show success message
            setFormState({...initialFormState, success: true})
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const validateForm = () => {
        //clear errors
        setFormState(prevState => {return {...prevState, error: null}});

        //check that all fields are filled out
        if (!formState.oldPassword || !formState.newPassword || !formState.newPasswordConfirm) {
            setFormState(prevState => {
                return {...prevState, error: FormError.EMPTY_FIELDS}
            });
        //check that new password meets requirements
        } else if (formState.newPassword.length < 8) {
            setFormState(prevState => {
                return {...prevState, error: FormError.INVALID_LENGTH}
            });
        //check that passwords match
        } else if (formState.newPassword !== formState.newPasswordConfirm) {
            setFormState(prevState => {
                return {...prevState, error: FormError.MISMATCHED_PASSWORDS}
            });
        } else {
            //if valid, submit request
            submitPasswordReset.execute();
        }
    }

    const submitPasswordReset = useAsync<void>(onSubmit, "Error submitting your request. Please verify your password and try again.");

    return (
        <Modal
            isOpen={isOpen}
            hide={() => {
                //clear errors if any
                if (submitPasswordReset.error) {
                    submitPasswordReset.clearError();
                }
                //clear form and close modal
                setFormState(initialFormState);
                hide();
            }}
            onConfirm={!formState.success ? validateForm : onConfirm}
            showIcon={false}
            title={!formState.success ? 'Reset Password?' : 'Success'}
            confirmButtonText={!formState.success ? 'Confirm' : 'Okay'}
            cancelButtonText={'Cancel'}
            hideCancel={formState.success}
            content={
                <>
                    {!formState.success ?
                        <>
                            <LabeledTextInput
                                label={'Old Password: '}
                                placeholder={''}
                                value={formState.oldPassword}
                                width={'50%'}
                                labelWidth={'40%'}
                                onChange={e => {
                                    //saving value to variable to prevent synthetic event error
                                    const newValue = removeWhiteSpace(e.target.value);
                                    setFormState(prevState => {
                                        return { ...prevState, oldPassword: newValue };
                                    });
                                }}
                                required
                                type={'password'}
                                error={formState.error === FormError.EMPTY_FIELDS && !formState.oldPassword ? formState.error : ''}
                            />
                            <LabeledTextInput
                                label={'New Password: '}
                                placeholder={''}
                                value={formState.newPassword}
                                width={'50%'}
                                labelWidth={'40%'}
                                onChange={e => {
                                    //saving value to variable to prevent synthetic event error
                                    const newValue = removeWhiteSpace(e.target.value);
                                    setFormState(prevState => {
                                        return { ...prevState, newPassword: newValue };
                                    });
                                }}
                                required
                                type={'password'}
                                error={(formState.error === FormError.EMPTY_FIELDS && !formState.newPassword)
                                    || (formState.error === FormError.INVALID_LENGTH && formState.newPassword.length < 8) ? formState.error : ''}
                            />
                            <LabeledTextInput
                                label={'Confirm Password: '}
                                placeholder={''}
                                value={formState.newPasswordConfirm}
                                width={'50%'}
                                labelWidth={'40%'}
                                onChange={e => {
                                    //saving value to variable to prevent synthetic event error
                                    const newValue = removeWhiteSpace(e.target.value);
                                    setFormState(prevState => {
                                        return { ...prevState, newPasswordConfirm: newValue };
                                    });
                                }}
                                required
                                type={'password'}
                                error={(formState.error === FormError.EMPTY_FIELDS && !formState.newPasswordConfirm)
                                    || (formState.error === FormError.MISMATCHED_PASSWORDS && formState.newPasswordConfirm !== formState.newPassword) ? formState.error : ''}
                            />
                            {submitPasswordReset.error && <ErrorMessage text={submitPasswordReset.error} margin={'25px auto'} />}
                        </>
                    :
                        <InfoText text={'You have successfully reset your password.'} />
                    }
                </>
            }
        />
    )
}