export enum UrlParams {
    // patientId = ':patientId',
    // caregiverId = ':caregiverId'
}

export const NonAuthRouteNames = {
    Login: '/',
    CreateAccount: '/register',
    ForgotPassword: '/request-password',
    ResetPassword: '/reset',
    Unauthorized: '/unauthorized',
};

export const AuthRouteNames = {
    UserList: '/users',
    AdminList: '/admins',
    Data: '/data',
    Profile: '/profile',
    RedirectToStart: '/',
    PairResults: `/users/results`,
    NotFound: '/404'
};
