import { RFC } from 'shared/types/sharedTypes';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from 'shared/components/Loader';
import { AuthRouteNames } from '../navigation/routeNames';
import { UserContext } from '../context/UserContext';

export const RedirectToStart: RFC = () => {
    const { currentLogin } = useContext(UserContext);

    //if admin is logged in, direct to user list
    return <>{!!currentLogin ? <Redirect to={AuthRouteNames.UserList} /> : <LoadingSpinner />}</>;
};
