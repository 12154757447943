import React from 'react';
import { RowTitle, TableItem, TableRow } from '../../../components/Table';
import { UserRole } from 'shared/types/User';
import { Importance, PairResult } from 'shared/types/Result';
import { getImportanceString } from 'shared/constants/importanceStrings';
import { SurveyAnswer } from './SurveyAnswer';
import { ErrorMessage, InfoText } from 'shared/components/Text';
import { QuestionType } from 'shared/types/Question';

type SurveyResultSectionProps = {
    sectionTitle: string;
    sectionResults: PairResult[];
    isRanked?: boolean;
};

export const SurveyResultSection = ({
    sectionTitle,
    sectionResults,
    isRanked = true,
}: SurveyResultSectionProps) => {
    return (
        <>
            {!!sectionResults ?
                <>
                    {sectionResults.length ? (
                        <>
                            {sectionResults.map((result, index, array) => {
                                //for free text questions and incomplete surveys
                                const isPatientAnswer = result.answer.hasOwnProperty(UserRole.PATIENT);
                                const isPartnerAnswer = result.answer.hasOwnProperty(UserRole.CARE_PARTNER);

                                return (
                                    <TableRow rowIndex={index} key={result.id} isLastRow={index === array.length - 1}>
                                        {isRanked && result.questionType === QuestionType.IMPORTANCE ? (
                                            <>
                                                {(index === 0) && <RowTitle rowSpan={sectionResults.length}>
                                                    {`${sectionTitle} \n(${sectionResults.length})`}
                                                </RowTitle>}
                                                <TableItem>{result.text[UserRole.PATIENT] ?? result.text[UserRole.CARE_PARTNER]}</TableItem>
                                                <TableItem>
                                                    <SurveyAnswer
                                                        size={16}
                                                        text={isPatientAnswer ? getImportanceString(result.answer[UserRole.PATIENT] as Importance) : 'N/A'}
                                                        active={!!result.answer[UserRole.PATIENT] && result.answer[UserRole.PATIENT] !== Importance.NOT_IMPORTANT}
                                                    />
                                                </TableItem>
                                                <TableItem>
                                                    <SurveyAnswer
                                                        size={16}
                                                        text={isPartnerAnswer ? getImportanceString(result.answer[UserRole.CARE_PARTNER] as Importance) : 'N/A'}
                                                        active={!!result.answer[UserRole.CARE_PARTNER] && result.answer[UserRole.CARE_PARTNER] !== Importance.NOT_IMPORTANT}
                                                        isPrimary={false}
                                                    />
                                                </TableItem>
                                            </>
                                        ) : (
                                            <>
                                                {(index === 0) && <RowTitle rowSpan={sectionResults.length}>
                                                    {`${sectionTitle} \n(${sectionResults.length})`}
                                                </RowTitle>}
                                                <TableItem>
                                                    {isPatientAnswer
                                                        ? result.answer[UserRole.PATIENT]
                                                        : result.answer[UserRole.CARE_PARTNER]}
                                                </TableItem>
                                                <TableItem>
                                                    <SurveyAnswer
                                                        size={16}
                                                        text={isPatientAnswer ? 'Write-in' : 'N/A'}
                                                        active={isPatientAnswer}
                                                    />
                                                </TableItem>
                                                <TableItem>
                                                    <SurveyAnswer
                                                        size={16}
                                                        text={isPartnerAnswer ? 'Write-in' : 'N/A'}
                                                        active={isPartnerAnswer}
                                                        isPrimary={false}
                                                    />
                                                </TableItem>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            <TableRow rowIndex={0} isLastRow={true} >
                                <RowTitle rowSpan={1}>
                                    {`${sectionTitle} \n(${sectionResults.length})`}
                                </RowTitle>
                                <TableItem colSpan={3}>
                                    <InfoText text={'N/A'} />
                                </TableItem>
                            </TableRow>
                        </>
                        )
                    }
                </>
            :
                <ErrorMessage text={"No results found."} />
            }
        </>
    );
};
