import React from 'react';
import { render } from 'react-dom';
import App from './App';
import UserContextProvider from './context/UserContext';

render(
    <UserContextProvider>
        <App />
    </UserContextProvider>,
    document.getElementById('app')
);
