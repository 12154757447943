import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../components/Wrappers';
import { ColumnTitle, TableBody, TableContainer, TableHeader, TableRow } from '../../components/Table';
import { SurveyResultSection } from './components/SurveyResultSection';
import { BackToLink } from 'shared/components/Links';
import { AuthRouteNames } from '../../navigation/routeNames';
import { Theming } from '../../theming';
import styled from 'styled-components';
import { BaseButton } from 'shared/components/Buttons';
import { Select } from '../../components/Select';
import { ErrorMessage } from 'shared/components/Text';
import LoadingSpinner, { MiniLoadingSpinner } from 'shared/components/Loader';
import { AdaptedPairResults, adaptResultsToSections, ResultSections } from '../../../../shared/adapters/surveyAdapters';
import { useAsync } from 'shared/hooks/useAsync';
import { adminApiManager } from '../../network/apiManager';
import { useQuery } from 'shared/hooks/useQuery';
import { adaptDatesToSelectOption } from '../../adapters/surveyAdapters';
import { handleDownloadFile } from 'shared/utils/handleDownloadFile';
import { getImportanceString } from 'shared/constants/importanceStrings';
import { Importance } from 'shared/types/Result';

type SelectOption = {
    label: string
    value: string
}

type DateFilterOptions = {
    patientDates: SelectOption[]
    caregiverDates: SelectOption[]
}

type SelectedDatesState = {
    patientDate: string
    caregiverDate: string
}

export const PairResults = () => {
    //get query from url to fetch pair results
    const query = useQuery();
    const patient = decodeURIComponent(query.get("patient") ?? '');
    const caregiver = decodeURIComponent(query.get("caregiver") ?? '');

    const initialFilterOptions = {
        patientDates: [
            { label: 'N/A', value: '' }
        ],
        caregiverDates: [
            { label: 'N/A', value: '' }
        ]
    }

    const initialSelectedDates = {
        patientDate: '',
        caregiverDate: ''
    }

    //TODO: refactor state into one state to hold everything?
    const [adaptedPairResults, setAdaptedPairResults] = useState<AdaptedPairResults | null>(null ); //survey results formatted into sections for display

    const [dateFilterOptions, setDateFilterOptions] = useState<DateFilterOptions>(initialFilterOptions); //a list each for patient/caregiver of survey completion dates, ordered by most recent
    const [selectedDates, setSelectedDates] = useState<SelectedDatesState>(initialSelectedDates); //dates selected from dropdown filter
    const [enableUpdateButton, setEnableUpdateButton] = useState<boolean>(false); //update button is disabled unless new date is selected

    useEffect(() => {
        //fetch survey results and dates on load
        getPairResults.execute();
        getSurveyDates.execute();

        //clear errors on unmount
        return () => {
            getPairResults.clearError();
            getSurveyDates.clearError();
            downloadPDF.clearError();
        }
    }, []);

    useEffect(() => {
        //when selected date changes, enable update results button
        if (!!selectedDates.patientDate || !!selectedDates.caregiverDate) {
            setEnableUpdateButton(true);
        }
    }, [selectedDates])

    const getPairResultsAsync = async () => {
        try {
            //clear fetch error if any
            if (getPairResults.error) {
                getPairResults.clearError();
            }
            //get survey results
            if (patient && caregiver) {
                const getResultsRequest = {
                    patient,
                    caregiver,
                    patientDate: selectedDates.patientDate,
                    caregiverDate: selectedDates.caregiverDate
                }
                const pairSurveyResults = await adminApiManager.Results.getResults(getResultsRequest);
                //set to state
                if (pairSurveyResults) {
                    setAdaptedPairResults(adaptResultsToSections(pairSurveyResults));
                    //disable update button after updates have been made
                    if (enableUpdateButton) {
                        setEnableUpdateButton(false);
                    }
                }
            } else {
                throw new Error('Could not find pair.');
            }
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const getSurveyDatesAsync = async () => {
        try {
            const getDatesResponse = await adminApiManager.Results.getDates({});
            if (patient && caregiver) {
                //format date results as select options- if no results, set blank
                const patientDates = getDatesResponse[patient]?.completionDates ?
                    adaptDatesToSelectOption(getDatesResponse[patient].completionDates) : dateFilterOptions.patientDates;
                const caregiverDates = getDatesResponse[caregiver]?.completionDates ?
                    adaptDatesToSelectOption(getDatesResponse[caregiver].completionDates) : dateFilterOptions.caregiverDates;
                setDateFilterOptions({
                        patientDates: patientDates,
                        caregiverDates: caregiverDates
                });
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const onSubmitDownload = async () => {
        //clear any errors
        downloadPDF.clearError();
        try {
            if (patient && caregiver) {
                //submit request
                const pdfResultsRequest = {
                    patient: patient,
                    caregiver: caregiver,
                    patientDate: selectedDates.patientDate,
                    caregiverDate: selectedDates.caregiverDate
                }
                const downloadPdfResponse = await adminApiManager.Results.getPdfResults(pdfResultsRequest);
                await handleDownloadFile(downloadPdfResponse, 'pdf');
            }
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const getPairResults = useAsync<void>(getPairResultsAsync, "Error fetching results. Please check your connection and try again.");
    const getSurveyDates = useAsync<void>(getSurveyDatesAsync, "Error fetching data. Please check your connection and try again.")
    const downloadPDF = useAsync<any>(onSubmitDownload, "Error fetching pdf data. Please check your connection and try again.");

    return (
        <>
            {!!adaptedPairResults ?
                <>
                    <PageHeader>
                        <BackToLink path={AuthRouteNames.UserList} text={'back to user list'} />

                        <FiltersWrapper>
                            <FiltersLabel>Date taken: </FiltersLabel>
                            <Select
                                label={'Patient'}
                                options={dateFilterOptions.patientDates}
                                onChange={(newVal) => {
                                   setSelectedDates(prevState => {
                                       return {
                                           ...prevState,
                                           patientDate: newVal
                                       }
                                   });
                                }}
                            />
                            <Select
                                label={'Caregiver'}
                                options={dateFilterOptions.caregiverDates}
                                onChange={(newVal) => {
                                    setSelectedDates(prevState => {
                                        return {
                                            ...prevState,
                                            caregiverDate: newVal
                                        }
                                    });
                                }}
                            />
                            <ButtonWrapper>
                            <BaseButton
                                text={enableUpdateButton && getPairResults.pending ? <MiniLoadingSpinner /> : 'Update Results'}
                                width={'auto'}
                                fontSize={'16px'}
                                margin={'0 auto'}
                                padding={'6px 8px'}
                                isPrimary={false}
                                disabled={!enableUpdateButton}
                                onClick={() => {
                                    //update pair results with selected dates
                                    getPairResults.execute();
                                }}
                            />
                            </ButtonWrapper>
                        </FiltersWrapper>

                        <ButtonWrapper>
                            <BaseButton
                                text={!downloadPDF.pending ? 'Export PDF' : <MiniLoadingSpinner />}
                                width={'auto'}
                                disabled={!!downloadPDF.pending || !!downloadPDF.error}
                                onClick={downloadPDF.execute}
                            />
                        </ButtonWrapper>
                    </PageHeader>

                    {!!downloadPDF.error && <ErrorMessage text={downloadPDF.error} textAlign={'right'} margin={'20px 0'} />}

                    <PageContainer>
                        <TableContainer>
                            <TableHeader>
                                <TableRow>
                                    <ColumnTitle>Ranking</ColumnTitle>
                                    <ColumnTitle>Question</ColumnTitle>
                                    <ColumnTitle>{patient ?? 'Patient'}</ColumnTitle>
                                    <ColumnTitle>{caregiver ?? 'Caregiver'}</ColumnTitle>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                <SurveyResultSection
                                    sectionTitle={'Top Priority'}
                                    sectionResults={adaptedPairResults[ResultSections.TOP_PRIORITY]}
                                />
                                <SurveyResultSection
                                    sectionTitle={'Important to Both'}
                                    sectionResults={adaptedPairResults[ResultSections.IMPORTANT_TO_BOTH]}
                                />
                                <SurveyResultSection
                                    sectionTitle={'Important to Patient'}
                                    sectionResults={adaptedPairResults[ResultSections.IMPORTANT_TO_PATIENT]}
                                />
                                <SurveyResultSection
                                    sectionTitle={'Important to Caregiver'}
                                    sectionResults={adaptedPairResults[ResultSections.IMPORTANT_TO_CAREGIVER]}
                                />
                                <SurveyResultSection
                                    sectionTitle={'Non Ranked'}
                                    sectionResults={adaptedPairResults[ResultSections.NON_RANKED]}
                                    isRanked={false}
                                />
                                <SurveyResultSection
                                    sectionTitle={getImportanceString(Importance.NOT_IMPORTANT)}
                                    sectionResults={adaptedPairResults[ResultSections.NOT_IMPORTANT]}
                                />
                            </TableBody>
                        </TableContainer>
                    </PageContainer>
                </>
            :
                !!getPairResults.pending ?
                    <LoadingSpinner />
                    :
                    <>
                        <BackToLink path={AuthRouteNames.UserList} text={'back to user list'} />
                        <ErrorMessage text={getPairResults.error ?? 'No results found.'} />
                    </>
            }
        </>
    );
};

const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    flex-wrap: wrap;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        display: block;
    }
`;

const FiltersWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        display: block;
        margin: 20px auto;
        width: 70%;
    }
`;

const FiltersLabel = styled.div`
    text-align: center;
    font-family: ${Theming.regularFont};
    color: ${Theming.secondaryTextColor};
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        text-align: left;
        font-weight: bold;
    }
`;

const ButtonWrapper = styled.div`
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
            width: 100%;
        }
`;
