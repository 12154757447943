import React, { useState } from 'react';
import { Modal, ModalProps } from 'shared/components/Modal';
import { ErrorMessage } from 'shared/components/Text';
import { useAsync } from 'shared/hooks/useAsync';
import { LabeledTextInput } from 'shared/components/Input';
import { isValidEmail } from 'shared/utils/validators';
import { adminApiManager } from '../../network/apiManager';
import { removeWhiteSpace } from 'shared/utils/removeWhiteSpace';

type FormState = {
    firstName: string
    lastName: string
    email: string
    institution: string
    error: FormError | null;
}

enum FormError {
    EMPTY_FIELDS = "Please fill out all fields.",
    INVALID_EMAIL = "Please enter valid email address."
}

export const CreateAdmin = ({ isOpen, hide, onConfirm }: ModalProps) => {
    const initialFormState = {
        firstName: '',
        lastName: '',
        email: '',
        institution: '',
        error: null,
    }
    const [formState, setFormState] = useState<FormState>(initialFormState);

    const onSubmit = async () => {
        try {
            const newAdmin = {
                firstName: formState.firstName,
                lastName: formState.lastName,
                email: formState.email,
                institution: formState.institution
            }
            await adminApiManager.Users.createAdmin(newAdmin);

            //clear form and close modal on success
            setFormState(initialFormState);
            onConfirm();
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const validateForm = () => {
        //clear any errors
        if (formState.error) {
            setFormState(prevState => {
                return {...prevState, error: null}
            })
        }
        if (createAdmin.error) {
            createAdmin.clearError();
        }

        //check that all form fields have been filled out and email is valid
        const validEmail = isValidEmail(formState.email);

        if (!formState.firstName || !formState.lastName || !formState.email || !formState.institution) {
            setFormState(prevState => {
                return {...prevState, error: FormError.EMPTY_FIELDS}
            })
        } else if (!validEmail) {
            setFormState(prevState => {
                return {...prevState, error: FormError.INVALID_EMAIL}
            })
        } else {
            createAdmin.execute();
        }
    }

    const createAdmin = useAsync<void>(onSubmit, "Error saving your data. Please check your connection and try again.")

    return (
        <Modal
            isOpen={isOpen}
            hide={() => {
                //clear errors if any
                if (createAdmin.error) {
                    createAdmin.clearError();
                }
                //clear any unsaved form data or form errors
                setFormState(initialFormState);

                //close modal
                hide();
            }}
            onConfirm={validateForm}
            showIcon={false}
            title={'Add New Admin'}
            confirmButtonText={'Create Admin'}
            cancelButtonText={'Cancel'}
            content={
                <>
                    <LabeledTextInput
                        label={'First Name: '}
                        placeholder={'Enter first name'}
                        value={formState.firstName}
                        onChange={e => {
                            //saving value to variable to prevent synthetic event error
                            const newValue = e.target.value;
                            setFormState(prevState => {
                                return { ...prevState, firstName: newValue };
                            });
                        }}
                        required
                        error={!!formState.error && !formState.firstName ? formState.error : ''}
                    />
                    <LabeledTextInput
                        label={'Last Name: '}
                        placeholder={'Enter last name'}
                        value={formState.lastName}
                        onChange={e => {
                            //saving value to variable to prevent synthetic event error
                            const newValue = e.target.value;
                            setFormState(prevState => {
                                return { ...prevState, lastName: newValue };
                            });
                        }}
                        required
                        error={!!formState.error && !formState.lastName ? formState.error : ''}
                    />
                    <LabeledTextInput
                        label={'Email: '}
                        placeholder={'Enter email address'}
                        value={formState.email}
                        onChange={e => {
                            //saving value to variable to prevent synthetic event error
                            const newValue = removeWhiteSpace(e.target.value);
                            setFormState(prevState => {
                                return { ...prevState, email: newValue };
                            });
                        }}
                        required
                        error={(formState.error === FormError.EMPTY_FIELDS && !formState.email) || (formState.error === FormError.INVALID_EMAIL && !isValidEmail(formState.email)) ? formState.error : ''}
                    />
                    <LabeledTextInput
                        label={'Institution: '}
                        placeholder={'Enter institution'}
                        value={formState.institution}
                        onChange={e => {
                            //saving value to variable to prevent synthetic event error
                            const newValue = e.target.value;
                            setFormState(prevState => {
                                return { ...prevState, institution: newValue };
                            });
                        }}
                        required
                        error={!!formState.error && !formState.institution ? formState.error : ''}
                    />
                    {createAdmin.error && <ErrorMessage text={createAdmin.error} margin={'25px auto'} />}
                </>
            }
        />
    )
}