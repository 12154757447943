import React, { useState } from 'react';
import { PageTitle, StyledInfoText } from 'shared/components/Text';
import { StyledInternalLink } from 'shared/components/Links';
import {  UnlabeledTextInput } from 'shared/components/Input';
import { BaseButton } from 'shared/components/Buttons';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { isValidEmail } from 'shared/utils/validators';
import { NonAuthRouteNames } from '../../../navigation/routeNames';
import { removeWhiteSpace } from 'shared/utils/removeWhiteSpace';

type LoginFormProps = {
    onSubmit: (login: { username: string; password: string }) => void;
};

type FormState = {
    email: string;
    password: string;
    error: FormError | null;
};

enum FormError {
    INVALID_EMAIL = "Invalid email address.",
    INVALID_PASSWORD_LENGTH = "Your password must be at least 8 characters."
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
    const initialFormState = {
        email: '',
        password: '',
        error: null
    };

    const [formState, setFormState] = useState<FormState>(initialFormState);

    const validateForm = () => {
        //clear any prior errors
        setFormState(prevState => {
            return { ...prevState, error: null };
        });

        const validEmail = isValidEmail(formState.email);
        const validPassword = formState.password.length >= 8;

        //set form to valid if username & password, else set form errors
        if (!validEmail) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.INVALID_EMAIL };
            });
        } else if (!validPassword) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.INVALID_PASSWORD_LENGTH };
            });
        } else {
            onSubmit({
                username: formState.email,
                password: formState.password,
            });
        }
    };

    return (
        <LoginFormContainer>
            <PageTitle text={'PACES Admin Portal'} />
            <StyledInfoText>Please enter your email address and password below.</StyledInfoText>
            <UnlabeledTextInput
                value={formState.email}
                onChange={e => {
                    //saving value to variable to prevent synthetic event error
                    const newValue = removeWhiteSpace(e.target.value);
                    setFormState(prevState => {
                        return { ...prevState, email: newValue };
                    });
                }}
                placeholder={'Email Address'}
                type={'text'}
                error={formState.error === FormError.INVALID_EMAIL && !isValidEmail(formState.email) ? formState.error : ''}
                required
            />
            <UnlabeledTextInput
                value={formState.password}
                onChange={e => {
                    //saving value to variable to prevent synthetic event error
                    const newValue = removeWhiteSpace(e.target.value);
                    setFormState(prevState => {
                        return { ...prevState, password: newValue };
                    });
                }}
                placeholder={'Password'}
                type={'password'}
                error={formState.error === FormError.INVALID_PASSWORD_LENGTH && formState.password.length < 8 ? formState.error : ''}
                required
            />
            <BaseButton text={'Sign In'} onClick={validateForm} />
            <LinkWrapper>
                <StyledInternalLink to={NonAuthRouteNames.ForgotPassword}>Forgot Password?</StyledInternalLink>
            </LinkWrapper>
        </LoginFormContainer>
    );
};

const LoginFormContainer = styled.div`
    box-styling: border-box;
    padding: 40px;
    margin: 40px auto;
    width: 50%;
    background-color: ${Theming.pageBackgroundColor};
    border-radius: 8px;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        width: 80%;
    }
`;

const LinkWrapper = styled.div`
  text-align: center;
  margin: 20px auto 5px;
`;
