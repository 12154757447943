import React from 'react';
import { StyledInternalLink } from 'shared/components/Links';
import styled from 'styled-components';
import { Theming } from '../theming';

export const NavLink = ({ isActive, path, text }) => {
    return (
        <NavLinkWrapper isActive={isActive}>
            {!isActive ? (
                <StyledInternalLink to={path} $removeUnderline>
                    {text}
                </StyledInternalLink>
            ) : (
                <NavLinkDisabled>{text}</NavLinkDisabled>
            )}
        </NavLinkWrapper>
    );
};

const NavLinkDisabled = styled.span`
    color: ${Theming.primaryButtonColor};
    font-family: ${Theming.regularFont};
    text-decoration: underline;
`;

export const NavLinks = styled.div`
    font-family: ${Theming.regularFont};
    font-size: 1.25rem;
    color: ${Theming.primaryTextColor};
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        font-size: 1rem;
    }
`;

const NavLinkWrapper = styled.div<{ isActive: boolean }>`
    font-variant: small-caps;
    display: inline-block;
    padding: 10px 50px;
    margin: 0 5px;
    border-radius: 5px;
    background-color: ${({ isActive }) => (isActive ? Theming.primaryBackgroundColor : 'transparent')};
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        padding: 10px;
    }
    @media (max-width: ${Theming.breakpoints.mobile}px) {
        padding: 5px;
    }
    cursor: default;
    :hover& {
        text-decoration: ${({ isActive }) => (!isActive ? 'underline' : 'none')};
    }
`;

export const NavBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin: 0 0 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
`;
// border-bottom: solid 1px #D9D9D9;
// border-top: solid 1px #F9F9F9;
