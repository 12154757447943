import React, { useState } from 'react';
import { Modal } from 'shared/components/Modal';
import { adminApiManager } from '../../network/apiManager';
import { useAsync } from 'shared/hooks/useAsync';
import { ErrorMessage, InfoText, PageTitle } from 'shared/components/Text';
import { isValidEmail } from 'shared/utils/validators';
import { UnlabeledTextInput } from 'shared/components/Input';
import { NonAuthRouteNames } from '../../navigation/routeNames';
import { Theming } from '../../theming';
import { BackToLink } from 'shared/components/Links';
import { BaseButton } from 'shared/components/Buttons';
import styled from 'styled-components';
import { removeWhiteSpace } from 'shared/utils/removeWhiteSpace';


export const ForgotPassword = () => {
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const onSubmit = async () => {
        //clear errors
        submitPasswordReset.clearError();
        try {
            await adminApiManager.Auth.resetPasswordRequest({ username: emailAddress }, 'admin');
            setShowConfirmation(true);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const validateEmail = () => {
        //clear errors
        setError('');
        const isValid = isValidEmail(emailAddress);
        if (isValid) {
            submitPasswordReset.execute();
        } else {
            setError('Invalid email address.');
        }
    }

    const submitPasswordReset = useAsync<void>(onSubmit, "Error submitting your request. Please check your connection and try again.");

    return (
        <>
            <BackToLink path={NonAuthRouteNames.Login} text={'Back to Login'} />
            <FormWrapper>
                <PageTitle text={'Reset Password'} textAlign={'center'} margin={'20px auto'} />
                <InfoText text={'A link to reset your password will be sent to your email address.'} />
                <UnlabeledTextInput
                    placeholder={'Enter Email Address'}
                    value={emailAddress}
                    width={'50%'}
                    disabled={submitPasswordReset.pending}
                    onChange={e => {
                        //saving value to variable to prevent synthetic event error
                        const newValue = removeWhiteSpace(e.target.value);
                        setEmailAddress(newValue);
                    }}
                    error={error && !isValidEmail(emailAddress) ? error: ''}
                    required
                />
                <ButtonWrapper>
                    <BaseButton
                        text={'Submit'}
                        width={'auto'}
                        padding={'8px 14px'}
                        onClick={validateEmail}
                    />
                </ButtonWrapper>

                {submitPasswordReset.error && <ErrorMessage text={submitPasswordReset.error} margin={'25px auto'} />}
            </FormWrapper>

            <Modal
                isOpen={showConfirmation}
                hide={() => setShowConfirmation(false)}
                onConfirm={() => setShowConfirmation(false)}
                showIcon={false}
                title={'Success'}
                confirmButtonText={'Okay'}
                hideCancel
                content={
                    <InfoText text={`A link to reset your password has been sent to ${emailAddress}`} />
                }
            />
        </>
    )
}

const ButtonWrapper = styled.div`
  display: inline-block;
  text-align: center;
  margin: 0 0 0 20px;
`;

const FormWrapper = styled.div`
    box-styling: border-box;
    text-align: center;
    padding: 40px;
    margin: 40px auto;
    width: 50%;
    background-color: ${Theming.pageBackgroundColor};
    border-radius: 8px;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        width: 80%;
    }
`;