import React, { ReactNode, useContext } from 'react';
import { Route } from 'react-router-dom';
import { RFC } from 'shared/types/sharedTypes';
import { UserContext } from '../context/UserContext';
import { Unauthorized } from 'shared/components/Unauthorized';
import { NonAuthRouteNames } from './routeNames';

interface NonAuthRouteProps {
    component: ReactNode;
    path?: string;
    exact?: boolean;
}

export const NonAuthRoute = ({ component, path, exact = false }: NonAuthRouteProps) => (
    <Route exact={exact} path={path}>
        {component}
    </Route>
);

interface AuthRouteProps {
    component: ReactNode;
    path?: string;
    exact?: boolean;
}

export const AuthRoute: RFC<AuthRouteProps> = ({
    component,
    path,
    exact = false,
}) => {
    const { currentLogin } = useContext(UserContext);
    return !!currentLogin ? (
        <>
            <NonAuthRoute path={path} component={component} exact={exact} />
        </>
    ) : (
        <NonAuthRoute component={Unauthorized} path={NonAuthRouteNames.Unauthorized} />
    );
};
