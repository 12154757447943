import React, { useState } from 'react';
import { Modal, ModalProps } from 'shared/components/Modal';
import { ModalContentWrapper } from '../../components/Wrappers';
import { InputLabel } from 'shared/components/Input';
import { ErrorMessage, InfoText } from 'shared/components/Text';
import { useAsync } from 'shared/hooks/useAsync';
import { adminApiManager } from '../../network/apiManager';
import { NonAdmin } from 'shared/types/User';
import { ButtonLink } from 'shared/components/Buttons';
import styled from 'styled-components';
import { Theming } from '../../theming';

export const ManageUserPasswords = ({ isOpen, hide, onConfirm, selectedPatient }: ModalProps & { selectedPatient: NonAdmin | null }) => {

    const [successMessage, setSuccessMessage] = useState<string>('')

    const sendResetLink = async (username: string) => {
        //clear any errors
        resetPassword.clearError();
        try {
            await adminApiManager.Auth.resetPasswordRequest({ username }, 'nonAdmin');
            //set success message
            setSuccessMessage(`A link to reset ${username}'s password has been sent to your email address.`)
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const sendInviteLink = async (username: string) => {
        //clear any errors
        resetPassword.clearError();
        try {
            await adminApiManager.Auth.resendInvitation({ username });
            //set success message
            setSuccessMessage(`A new invite link for ${username} has been sent to your email address.`)
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const resetPassword = useAsync<void>(
        async (username: string) => sendResetLink(username),
        "Error sending your request. Please check your connection and try again."
    );
    const resendInvite = useAsync<void>(
        async (username: string) => sendInviteLink(username),
        "Error sending your request. Please check your connection and try again."
    );

    return (
        <>
            <Modal
                isOpen={isOpen}
                hide={hide}
                onConfirm={() => {
                    //clear any errors on close
                    if (resetPassword.error) {
                        resetPassword.clearError();
                    }
                    if (resendInvite.error) {
                        resendInvite.clearError();
                    }
                    //clear message on close
                    setSuccessMessage('');
                    //close modal
                    hide()
                }}
                showIcon={false}
                title={'Manage User Passwords'}
                confirmButtonText={'Close'}
                hideCancel={true}
                content={
                    <>
                        {!!selectedPatient ?
                            <ModalContentWrapper>
                                    <InviteRow>
                                        <InputLabel color={Theming.secondaryTextColor} width={'20%'}>Patient: </InputLabel>
                                        <InfoText
                                            text={selectedPatient.username}
                                            color={Theming.primaryTextColor}
                                            inline
                                            bold={selectedPatient.confirmed}
                                            italic={!selectedPatient.confirmed}
                                        />
                                        <ButtonWrapper>
                                        {selectedPatient.confirmed ?
                                            <ButtonLink
                                                text={'Reset Password'}
                                                fontSize={'16px'}
                                                color={Theming.primaryButtonColor}
                                                onClick={() => {
                                                    resetPassword.execute(selectedPatient?.username)
                                                }}
                                            />
                                        :
                                            <ButtonLink
                                                text={'Resend Invite'}
                                                fontSize={'16px'}
                                                color={Theming.secondaryButtonColor}
                                                onClick={() => {
                                                    resendInvite.execute(selectedPatient?.username)
                                                }}
                                            />
                                        }
                                        </ButtonWrapper>
                                    </InviteRow>
                                    <InviteRow>
                                        <InputLabel color={Theming.secondaryTextColor} width={'20%'}>Caregiver: </InputLabel>
                                        <InfoText
                                            text={selectedPatient.pairUsername}
                                            color={Theming.primaryTextColor}
                                            inline
                                            bold={selectedPatient.pairConfirmed}
                                            italic={!selectedPatient.pairConfirmed}
                                        />
                                        <ButtonWrapper>
                                        {selectedPatient.pairConfirmed ?
                                            <ButtonLink
                                                text={'Reset Password'}
                                                fontSize={'16px'}
                                                color={Theming.primaryButtonColor}
                                                onClick={() => {
                                                    resetPassword.execute(selectedPatient.pairUsername)
                                                }}
                                            />
                                        :
                                            <ButtonLink
                                                text={'Resend Invite'}
                                                fontSize={'16px'}
                                                color={Theming.secondaryButtonColor}
                                                onClick={() => {
                                                    resendInvite.execute(selectedPatient.pairUsername)
                                                }}
                                            />
                                        }
                                        </ButtonWrapper>
                                    </InviteRow>
                                {resetPassword.error && <ErrorMessage text={resetPassword.error} margin={'10px auto 25px'} />}
                                {successMessage && <InfoText text={successMessage} />}
                            </ModalContentWrapper>
                        :
                            <ErrorMessage text={'Error selecting pair. Please check your connection and try again.'} />
                        }
                    </>
                }
            />
        </>
    )
}

const InviteRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
    width: 35%;
`;