import React from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { PageContainer } from '../../components/Wrappers';
import { CreateAccountForm } from 'shared/components/CreateAccountForm';
import styled from 'styled-components';
import { adminApiManager } from '../../network/apiManager';
import { AuthRouteNames, NonAuthRouteNames } from '../../navigation/routeNames';
import { UserContext } from '../../context/UserContext';

export const CreateAccount: RFC = () => {
    return (
        <PageContainer>
            <FormContainer>
                <CreateAccountForm
                    apiManager={adminApiManager}
                    initialPath={NonAuthRouteNames.CreateAccount}
                    redirectPath={AuthRouteNames.RedirectToStart}
                    userContext={UserContext}
                />
            </FormContainer>
        </PageContainer>
    )
}

const FormContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
`;