import { Modal, ModalProps } from 'shared/components/Modal';
import { ErrorMessage, InfoText } from 'shared/components/Text';
import React from 'react';
import { useAsync } from 'shared/hooks/useAsync';
import { Admin } from 'shared/types/User';
import { adminApiManager } from '../../network/apiManager';

export const RemoveAdmin = ({ isOpen, hide, onConfirm, selectedAdmin }: ModalProps & { selectedAdmin: Admin | null }) => {
    const onSubmit = async () => {
        try {
            if (selectedAdmin) {
                await adminApiManager.Users.deleteAdmin({ username: selectedAdmin.username })
                //close modal, clear selected admin, and refresh admin list on success
                onConfirm();
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const removeAdmin = useAsync<void>(onSubmit, "Error sending your request. \nPlease check your connection and try again.")

    return (
        <>
            {!!selectedAdmin &&
                <Modal
                    isOpen={isOpen}
                    hide={() => {
                        //clear errors if any
                        if (removeAdmin.error) {
                            removeAdmin.clearError();
                        }
                        //close modal
                        hide();
                    }}
                    onConfirm={removeAdmin.execute}
                    showIcon={false}
                    title={'Remove Admin?'}
                    confirmButtonText={'Confirm'}
                    cancelButtonText={'Cancel'}
                    content={
                        <>
                            <InfoText
                                text={`Are you sure you want to remove ${selectedAdmin.firstName} ${selectedAdmin.lastName}? This action cannot be undone.`} />
                            {removeAdmin.error && <ErrorMessage text={removeAdmin.error} margin={'25px auto'} />}
                        </>
                    }
                />}
        </>
    )
}