import styled from 'styled-components';
import { Theming } from '../theming';

export const MainContainer = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 10px;
    width: 90%;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        width: 95%;
    }
    font-family: ${Theming.regularFont};
`;

export const PageContainer = styled.div`
    box-sizing: border-box;
    background-color: ${Theming.pageBackgroundColor};
    margin: 10px auto 20px;
    border-radius: 8px;
    padding: 20px;
    overflow-x: auto;
    overflow-y: auto;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.1);
`;

export const ModalContentWrapper = styled.div`
  margin: 20px auto;
`;